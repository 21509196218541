<template>
  <div>
    <div>
      <button type="button" class="btn btn-primary" @click="imprimir()">IMPRIMIR</button>
    </div>
    <div style="font-family: Arial; font-size:10px;" class="container-fluid" id="print">
                            <div class="page page-breaker" >
                    <!--
                    INICIO DO CABECALHO
                    -->
                    <table class="table border-black">
                        <tr>
                            <td class="align-middle px-3">
                                <img width="100%" src="http://localhost:2020/images/logo23.png" style="width:100px" />
                            </td>
                            <td class="border-top-black">
                                <div class="row mx-0">
                                    <div class="col-md-12 font-weight-bold text-center" style="font-size:12px;">
                                        TIPO DE EVENTO
                                    </div>
                                    <div class="col-md-12" style="font-size: 8px;">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="p-1">
                                                    <div class="mark-box float-left"></div>
                                                    CURSO
                                                </div>
                                                <div class="p-1">
                                                    <div class="mark-box bg-dark float-left"></div>
                                                    TREINAMENTO LEGAL
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="p-1">
                                                    <div class="mark-box float-left"></div>
                                                    CURSO
                                                </div>
                                                <div class="p-1">
                                                    <div class="mark-box bg-dark float-left"></div>
                                                    TREINAMENTO LEGAL
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center font-weight-bold" style="font-size: 18px;"> LISTA DE PRESENÇA </td>
                            <td>
                                <div class="row mx-0 text-center font-weight-bold" style="font-size:12px;">
                                    <div class="col-md-12">
                                        CÓDIGO
                                    </div>
                                    <div class="col-md-12">
                                        #45645674
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> ATIVIDADE: NR33-Vigias E Trabalhadores Autorizados Reciclagem. </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> INSTRUTORES (A): Genilson de Souza Campos (08.00 horas) </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> OBJETIVOS: Estabelecer os requisitos mínimos para identificação de espaços confinados e o reconhecimento, avaliação, monitoramento e controle dos riscos existentes, de forma a garantir permanentemente a segurança e saúde dos trabalhadores que interagem direta ou indiretamente nestes espaços. </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4" class="p-0">
                                <table class="table text-center">
                                    <tr>
                                        <td class="align-middle"  style="border: none; border-right: 1px solid #000;"> LOCAL: Caeté </td>
                                        <td class="align-middle"  style="border: none; border-right: 1px solid #000;"> SALA: Jaguar Mining </td>
                                        <td style="border: none; border-right: 1px solid #000;">
                                            <div>PERÍODO:</div>
                                            <div>07/10/2019 a 07/10/2019</div>
                                        </td>
                                        <td class="align-middle" style="border: none; border-right: 1px solid #000;">CARGA HORARIA</td>
                                        <td class="align-middle border-0">08:00</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4" class="p-0">
                                <table class="table text-center">
                                    <tr>
                                        <td style="border: none; border-right: 1px solid #000;">
                                            <div>ENTIDADE:</div>
                                            <div class="font-weight-normal">GRUPO GESTOR VIDA</div>
                                        </td>
                                        <td style="border: none; border-right: 1px solid #000;" class="align-middle" >
                                            CNPJ: <span class="font-weight-normal">  30.223.490/0001-56</span>
                                        </td>
                                        <td style="border: none; border-right: 1px solid #000;" class="align-middle">CONDIÇAO</td>
                                        <td style="border: none;" class="align-middle">08 horas teoricas e praticas</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="height: 5px; background-color: rgb(0, 0, 0);"></td>
                        </tr>
                    </table>
                    <!--
                    FIM DO CABECALHO
                    -->
                    <!--
                    INICIO DO CORPO
                    -->
                    <table class="table border-black">
                        <tr>
                            <td colspan="4">
                                <table class="table text-center">
                                    <thead>
                                        <tr >
                                            <td  style="border: 0; border-bottom: 1px solid #000; border-top: 1px solid #000;" >ITEM</td>
                                            <td class="border-bottom-black border-right-black">NOME</td>
                                            <td class="border-bottom-black border-right-black">CPF</td>
                                            <td class="border-bottom-black border-right-black">CARGO</td>
                                            <td class="border-bottom-black border-right-black">EMPRESA</td>
                                            <td class="border-bottom-black border-right-black">____/____/______</td>
                                            <td class="border-bottom-black ">NOTA</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">0</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">25</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">65</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">45</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">98</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">5</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">13</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">256</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">235</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">254</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3698</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">354</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">215</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">1256</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">2547</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">215</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">325</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">369</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">254</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">128</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">475</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                            </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <!--
                    FIM DO CORPO
                    -->
                    <!--
                    INICIO DO RODAPE
                    -->
                    <table class="table border-black mt-3 align-self-end font-weight-bold">
                        <tr>
                            <td>
                                <div class="row">
                                    <div style="font-size: 8px;" class="col-md-6 text-right">
                                        <div>GRUPO GESTOR VIDA</div>
                                        <div>GESTÃO EM SAÚDE,SEGURANÇA DO TRABALHO E MEIO AMBIENTE</div>
                                        <div style="font-size: 7px;">SEDE: Rua Ismael Silva 540, Ideal, Ipatinga - MG</div>
                                    </div>
                                    <div class="col-md-5 text-center align-self-end">
                                        <div class="border-bottom-black">

                                        </div>
                                        <div style="font-size: 12px;">
                                            ASSINATURA GRUPO GESTOR VIDA
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!--
                    FIM DO RODAPE
                    -->
                </div>
                            <div class="page page-breaker" >
                    <!--
                    INICIO DO CABECALHO
                    -->
                    <table class="table border-black">
                        <tr>
                            <td class="align-middle px-3">
                                <img width="100%" src="http://localhost:2020/images/logo23.png" style="width:100px" />
                            </td>
                            <td class="border-top-black">
                                <div class="row mx-0">
                                    <div class="col-md-12 font-weight-bold text-center" style="font-size:12px;">
                                        TIPO DE EVENTO
                                    </div>
                                    <div class="col-md-12" style="font-size: 8px;">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="p-1">
                                                    <div class="mark-box float-left"></div>
                                                    CURSO
                                                </div>
                                                <div class="p-1">
                                                    <div class="mark-box bg-dark float-left"></div>
                                                    TREINAMENTO LEGAL
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="p-1">
                                                    <div class="mark-box float-left"></div>
                                                    CURSO
                                                </div>
                                                <div class="p-1">
                                                    <div class="mark-box bg-dark float-left"></div>
                                                    TREINAMENTO LEGAL
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center font-weight-bold" style="font-size: 18px;"> LISTA DE PRESENÇA </td>
                            <td>
                                <div class="row mx-0 text-center font-weight-bold" style="font-size:12px;">
                                    <div class="col-md-12">
                                        CÓDIGO
                                    </div>
                                    <div class="col-md-12">
                                        #45645674
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> ATIVIDADE: NR33-Vigias E Trabalhadores Autorizados Reciclagem. </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> INSTRUTORES (A): Genilson de Souza Campos (08.00 horas) </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> OBJETIVOS: Estabelecer os requisitos mínimos para identificação de espaços confinados e o reconhecimento, avaliação, monitoramento e controle dos riscos existentes, de forma a garantir permanentemente a segurança e saúde dos trabalhadores que interagem direta ou indiretamente nestes espaços. </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4" class="p-0">
                                <table class="table text-center">
                                    <tr>
                                        <td class="align-middle"  style="border: none; border-right: 1px solid #000;"> LOCAL: Caeté </td>
                                        <td class="align-middle"  style="border: none; border-right: 1px solid #000;"> SALA: Jaguar Mining </td>
                                        <td style="border: none; border-right: 1px solid #000;">
                                            <div>PERÍODO:</div>
                                            <div>07/10/2019 a 07/10/2019</div>
                                        </td>
                                        <td class="align-middle" style="border: none; border-right: 1px solid #000;">CARGA HORARIA</td>
                                        <td class="align-middle border-0">08:00</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4" class="p-0">
                                <table class="table text-center">
                                    <tr>
                                        <td style="border: none; border-right: 1px solid #000;">
                                            <div>ENTIDADE:</div>
                                            <div class="font-weight-normal">GRUPO GESTOR VIDA</div>
                                        </td>
                                        <td style="border: none; border-right: 1px solid #000;" class="align-middle" >
                                            CNPJ: <span class="font-weight-normal">  30.223.490/0001-56</span>
                                        </td>
                                        <td style="border: none; border-right: 1px solid #000;" class="align-middle">CONDIÇAO</td>
                                        <td style="border: none;" class="align-middle">08 horas teoricas e praticas</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="height: 5px; background-color: rgb(0, 0, 0);"></td>
                        </tr>
                    </table>
                    <!--
                    FIM DO CABECALHO
                    -->
                    <!--
                    INICIO DO CORPO
                    -->
                    <table class="table border-black">
                        <tr>
                            <td colspan="4">
                                <table class="table text-center">
                                    <thead>
                                        <tr >
                                            <td  style="border: 0; border-bottom: 1px solid #000; border-top: 1px solid #000;" >ITEM</td>
                                            <td class="border-bottom-black border-right-black">NOME</td>
                                            <td class="border-bottom-black border-right-black">CPF</td>
                                            <td class="border-bottom-black border-right-black">CARGO</td>
                                            <td class="border-bottom-black border-right-black">EMPRESA</td>
                                            <td class="border-bottom-black border-right-black">____/____/______</td>
                                            <td class="border-bottom-black ">NOTA</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">75</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4784</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">45785</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">457</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">548</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">7457</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">455758</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">788</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">442</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">687</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">414</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">69</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">41</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">75</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4774</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4787</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4587</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                            </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <!--
                    FIM DO CORPO
                    -->
                    <!--
                    INICIO DO RODAPE
                    -->
                    <table class="table border-black mt-3 align-self-end font-weight-bold">
                        <tr>
                            <td>
                                <div class="row">
                                    <div style="font-size: 8px;" class="col-md-6 text-right">
                                        <div>GRUPO GESTOR VIDA</div>
                                        <div>GESTÃO EM SAÚDE,SEGURANÇA DO TRABALHO E MEIO AMBIENTE</div>
                                        <div style="font-size: 7px;">SEDE: Rua Ismael Silva 540, Ideal, Ipatinga - MG</div>
                                    </div>
                                    <div class="col-md-5 text-center align-self-end">
                                        <div class="border-bottom-black">

                                        </div>
                                        <div style="font-size: 12px;">
                                            ASSINATURA GRUPO GESTOR VIDA
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!--
                    FIM DO RODAPE
                    -->
                </div>
                            <div class="page page-breaker" >
                    <!--
                    INICIO DO CABECALHO
                    -->
                    <table class="table border-black">
                        <tr>
                            <td class="align-middle px-3">
                                <img width="100%" src="http://localhost:2020/images/logo23.png" style="width:100px" />
                            </td>
                            <td class="border-top-black">
                                <div class="row mx-0">
                                    <div class="col-md-12 font-weight-bold text-center" style="font-size:12px;">
                                        TIPO DE EVENTO
                                    </div>
                                    <div class="col-md-12" style="font-size: 8px;">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="p-1">
                                                    <div class="mark-box float-left"></div>
                                                    CURSO
                                                </div>
                                                <div class="p-1">
                                                    <div class="mark-box bg-dark float-left"></div>
                                                    TREINAMENTO LEGAL
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="p-1">
                                                    <div class="mark-box float-left"></div>
                                                    CURSO
                                                </div>
                                                <div class="p-1">
                                                    <div class="mark-box bg-dark float-left"></div>
                                                    TREINAMENTO LEGAL
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center font-weight-bold" style="font-size: 18px;"> LISTA DE PRESENÇA </td>
                            <td>
                                <div class="row mx-0 text-center font-weight-bold" style="font-size:12px;">
                                    <div class="col-md-12">
                                        CÓDIGO
                                    </div>
                                    <div class="col-md-12">
                                        #45645674
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> ATIVIDADE: NR33-Vigias E Trabalhadores Autorizados Reciclagem. </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> INSTRUTORES (A): Genilson de Souza Campos (08.00 horas) </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4"> OBJETIVOS: Estabelecer os requisitos mínimos para identificação de espaços confinados e o reconhecimento, avaliação, monitoramento e controle dos riscos existentes, de forma a garantir permanentemente a segurança e saúde dos trabalhadores que interagem direta ou indiretamente nestes espaços. </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4" class="p-0">
                                <table class="table text-center">
                                    <tr>
                                        <td class="align-middle"  style="border: none; border-right: 1px solid #000;"> LOCAL: Caeté </td>
                                        <td class="align-middle"  style="border: none; border-right: 1px solid #000;"> SALA: Jaguar Mining </td>
                                        <td style="border: none; border-right: 1px solid #000;">
                                            <div>PERÍODO:</div>
                                            <div>07/10/2019 a 07/10/2019</div>
                                        </td>
                                        <td class="align-middle" style="border: none; border-right: 1px solid #000;">CARGA HORARIA</td>
                                        <td class="align-middle border-0">08:00</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td colspan="4" class="p-0">
                                <table class="table text-center">
                                    <tr>
                                        <td style="border: none; border-right: 1px solid #000;">
                                            <div>ENTIDADE:</div>
                                            <div class="font-weight-normal">GRUPO GESTOR VIDA</div>
                                        </td>
                                        <td style="border: none; border-right: 1px solid #000;" class="align-middle" >
                                            CNPJ: <span class="font-weight-normal">  30.223.490/0001-56</span>
                                        </td>
                                        <td style="border: none; border-right: 1px solid #000;" class="align-middle">CONDIÇAO</td>
                                        <td style="border: none;" class="align-middle">08 horas teoricas e praticas</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="height: 5px; background-color: rgb(0, 0, 0);"></td>
                        </tr>
                    </table>
                    <!--
                    FIM DO CABECALHO
                    -->
                    <!--
                    INICIO DO CORPO
                    -->
                    <table class="table border-black">
                        <tr>
                            <td colspan="4">
                                <table class="table text-center">
                                    <thead>
                                        <tr >
                                            <td  style="border: 0; border-bottom: 1px solid #000; border-top: 1px solid #000;" >ITEM</td>
                                            <td class="border-bottom-black border-right-black">NOME</td>
                                            <td class="border-bottom-black border-right-black">CPF</td>
                                            <td class="border-bottom-black border-right-black">CARGO</td>
                                            <td class="border-bottom-black border-right-black">EMPRESA</td>
                                            <td class="border-bottom-black border-right-black">____/____/______</td>
                                            <td class="border-bottom-black ">NOTA</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">47</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4258</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">45871</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">586</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">125</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">8447856</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">41785</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">40</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">45285240</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">547</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">1457</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">4175</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">757</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">567</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">874</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">8</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                                    <tr>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">54</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">gsdfdhbdfgyhsdfth</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">3453636345345</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">fgdfgh dfgd</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;">Jdfg dfh df</td>
                                                <td style="border: 0; border-top: 1px solid #000; border-right: 1px solid #000;"></td>
                                                <td style="border: 0; border-top: 1px solid #000;"></td>
                                            </tr>
                                                                            </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <!--
                    FIM DO CORPO
                    -->
                    <!--
                    INICIO DO RODAPE
                    -->
                    <table class="table border-black mt-3 align-self-end font-weight-bold">
                        <tr>
                            <td>
                                <div class="row">
                                    <div style="font-size: 8px;" class="col-md-6 text-right">
                                        <div>GRUPO GESTOR VIDA</div>
                                        <div>GESTÃO EM SAÚDE,SEGURANÇA DO TRABALHO E MEIO AMBIENTE</div>
                                        <div style="font-size: 7px;">SEDE: Rua Ismael Silva 540, Ideal, Ipatinga - MG</div>
                                    </div>
                                    <div class="col-md-5 text-center align-self-end">
                                        <div class="border-bottom-black">

                                        </div>
                                        <div style="font-size: 12px;">
                                            ASSINATURA GRUPO GESTOR VIDA
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!--
                    FIM DO RODAPE
                    -->
                </div>
            
        </div>
  </div>
</template>

<script>

export default {
  components: {
    
  },
  methods: {
    agruparDados () {
      let total = [1,2,3,4,5,6,7,8,9,0,12,23,34,45,56,67,78,89,90,333,444,654,3467,865, 432, 567, 987, 999, 222]
      let quantPorPagina = 12
      let quantPaginas = Math.ceil(total.length / quantPorPagina)
      let x = 0
      let aux = 1
      let dados = []
      for(let a = 0; a < quantPaginas; a++) {
        dados[a] = []
      }
      total.map( valor => {
        dados[x].push(valor)
        if(aux % quantPorPagina == 0) x++
        aux++
      })
      return dados
    },
    imprimir () {
       this.$htmlToPaper('print');
    }
  }
}
</script>

<style scoped>
@media print {
  @page {
    size: landscape
  }
  html {
    font-weight: bold;
  }
}
*,*::before,*::after{box-sizing:border-box;}
        body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";font-size:1rem;font-weight:400;line-height:1.5;color:#212529;text-align:left;background-color:#fff;}
        img{vertical-align:middle;border-style:none;}
        table{border-collapse:collapse;}
        .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
        .row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
        .col-md-5,.col-md-6,.col-md-12{position:relative;width:100%;padding-right:15px;padding-left:15px;}
        @media (min-width: 768px){
            .col-md-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
            .col-md-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
            .col-md-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
        }
        .table{width:100%;margin-bottom:1rem;color:#212529;}
        .table td{padding:0.75rem;vertical-align:top;border-top:1px solid #dee2e6;}
        .card{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid rgba(0, 0, 0, 0.125);border-radius:0.25rem;}
        .align-middle{vertical-align:middle!important;}
        .bg-dark{background-color:#343a40!important;}
        .border-0{border:0!important;}
        .align-self-end{-ms-flex-item-align:end!important;align-self:flex-end!important;}
        .float-left{float:left!important;}
        .mx-0{margin-right:0!important;}
        .mx-0{margin-left:0!important;}
        .mt-3{margin-top:1rem!important;}
        .p-0{padding:0!important;}
        .p-1{padding:0.25rem!important;}
        .px-3{padding-right:1rem!important;}
        .px-3{padding-left:1rem!important;}
        .text-right{text-align:right!important;}
        .text-center{text-align:center!important;}
        .font-weight-normal{font-weight:400!important;}
        .font-weight-bold{font-weight:700!important;}
        @media  print{
            *,*::before,*::after{text-shadow:none!important;box-shadow:none!important;}
            thead{display:table-header-group;}
            tr,img{page-break-inside:avoid;}
            body{min-width:992px!important;}
            .table{border-collapse:collapse!important;}
            .table td{background-color:#fff!important;}
        }
        /*! CSS Used from: Embedded */
        .page-breaker{page-break-after:always;}
        .table{margin-bottom:0px;}
        .mark-box{width:30px;height:20px;margin-right:5px;border:1px solid #000;}
        .table td{padding:0;}
        .border-right-black{border-right:1px solid #000;}
        .border-bottom-black{border-bottom:1px solid #000;}
        .border-top-black{border:1px solid #000;}
        .border-black,.border-black td{border:1px solid #000;}

</style>